import React from "react";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import HomePageSection from "../components/homePageSection";
import CTAInlink from "../components/CTAInLink";
import SEO from "../components/seo";

const Awards = ({ data }) => {
  return (
    <div>
      <h2 className="section-head text-center sm:text-6xl text-5xl lg:text-8xl">
        Associated With
      </h2>
      <div className="mt-16 flex flex-wrap flex-row justify-center items-center">
        <Img
          className="mr-4 mb-4"
          fixed={data.google.childImageSharp.fixed}
          alt="google logo"
          draggable={false}
          // objectFit="contain"
        />
        <Img
          className="mr-4 mb-4"
          fixed={data.zayed.childImageSharp.fixed}
          alt="zayed sustainability prize logo"
          draggable={false}
          // objectFit="cover"
        />
        <Img
          className="mr-4 mb-4"
          fixed={data.bloomEd.childImageSharp.fixed}
          alt="bloom ed logo"
          draggable={false}
          // objectFit="contain"
        />
        <Img
          className="mr-4 mb-4"
          fixed={data.canopy.childImageSharp.fixed}
          alt="canopy nepal logo"
          draggable={false}
          // objectFit="contain"
        />
        <Img
          className="mr-4 mb-4"
          fixed={data.mitSolve.childImageSharp.fixed}
          alt="MIT Solve logo"
          draggable={false}
          // objectFit="contain"
        />
      </div>
      <p className="caption text-center md:-mt-2">
        Copyright infringement not intended on logos above.
      </p>
      <p className="text-center mt-16">
        For questions and clarifications, email{" "}
        <a href="mailto:info@bloomn.edu.np" className="in-link">
          info@bloomn.edu.np
        </a>
      </p>
    </div>
  );
};

export const data = graphql`
  query {
    mitSolve: file(relativePath: { eq: "mit-solve.png" }) {
      childImageSharp {
        fixed(height: 25) {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    google: file(relativePath: { eq: "google-logo.png" }) {
      childImageSharp {
        fixed(height: 50) {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    canopy: file(relativePath: { eq: "canopy.jpeg" }) {
      childImageSharp {
        fixed(height: 75) {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bloomEd: file(relativePath: { eq: "bloom-ed.jpeg" }) {
      childImageSharp {
        fixed(height: 100) {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    zayed: file(relativePath: { eq: "zayed.png" }) {
      childImageSharp {
        fixed(height: 50) {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bloomMain: file(relativePath: { eq: "bloom-main.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bloomie: file(relativePath: { eq: "bloomie.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Subekshya: file(relativePath: { eq: "subekshya.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid
        }
      }
    }
    BinuGuitar: file(relativePath: { eq: "binu-guitar.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid
        }
      }
    }
    FBTeamOnGround: file(relativePath: { eq: "fb-team-on-ground.jpeg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default function Home({ data }) {
  return (
    <Layout>
      {/* <SEO title="Bloom Nepal School"/> */}
      <SEO title="Home" />
      <HomePageSection>
        <h1 className="block text-5xl md:text-7xl lg:text-9xl section-head text-center rainbow-text-animation">
          <span className="falling-animation animation-1 ">Bloom </span>
          <span className="falling-animation animation-2 ">Nepal </span>
          <span className="falling-animation animation-3 ">School </span>
        </h1>
        <Img
          className="w-full my-8 min-h-full"
          fluid={data.bloomMain.childImageSharp.fluid}
          alt="a team of student standing together"
          draggable={false}
        />
        <div className="my-8">
          <p className="">
            One of our founders,{" "}
            <span className="font-medium"> Ram K. Rijal</span>, was inspired by
            his highly talented peers while doing his undergraduate at MIT. Soon
            he noticed a trend among his friends, which was that they all had
            the opportunity to explore their area of interest from a small age.
          </p>
          <p className="mt-8">
            With the aim of establishing learning institutions in his home
            country, Nepal, of similar culture, Mr. Rijal returned home soon
            after finishing his finals (not even waiting for the graduation
            ceremony) to materialize his idea. The result, today, is Bloom Nepal
            School.
          </p>
        </div>
        <h2 className="my-8 self-start  section-head text-5xl sm:text-6xl lg:text-8xl">
          A{" "}
          <span className="section-head text-5xl sm:text-6xl lg:text-8xl text-red-700">
            {" "}
            Humble{" "}
          </span>{" "}
          Beginning
        </h2>

        <div>
          <p>
            Started with just 17 students in 2013, we now have more than 700 in
            two schools across the country, and we are growing!
          </p>
          <Link
            className="inline-block text-4xl text-left mt-8 border-b border-red-700 hover:text-red-700"
            to="stories/bloom_nepal_ram_k_rijal"
          >
            Read more{" "}
          </Link>
          <p className="inline-block px-2 sm:ml-4 text-sm">
            in Bloom Nepal's story covered in Pestalozzi News.
          </p>
        </div>
      </HomePageSection>
      <HomePageSection>
        <h2 className="text-center text-4xl md:text-6xl section-head">
          The magic is in the attitude{" "}
          <span className="text-red-700">stats are the proof</span>
        </h2>

        <p className="text-center mt-16">
          Our graduates have gone on to be UWC scholars in the US, Thailand, and
          many more countries. More than 80% are studying with scholarships in
          the top +2 colleges and universities in the country. Better yet, this
          is just the beginning.
        </p>
        <p className="text-center mt-4 sm:mt-8 lg:mt-16 ">
          The success of our graduates is the success of our, what might at
          first look like, eccentric, pedagogies. Especially our philosophy of
          Passion-Based Learning. Find more below!
        </p>
        <div className="mt-4 sm:mt-8 lg:mt-16">
          <CTAInlink
            text="our values"
            linkto="/about"
            className="bg-gray-800 text-white hover:bg-red-800 hover:text-white"
          />
        </div>
      </HomePageSection>
      <HomePageSection>
        {/* <PenaltySavedVideo
          videoSrcURL="https://www.youtube.com/embed?v=zavmyMOCpFY"
          videoTitle="Penalty Kick Saved by Keeper"
        /> */}
        <h2 className="text-center text-4xl md:text-6xl section-head">
          <span className="text-red-700">Passion</span> Based Learning
        </h2>
        <Img
          className="md:w-1/2 w-full mt-4"
          fluid={data.BinuGuitar.childImageSharp.fluid}
          alt="A female student playing guitar"
          draggable={false}
        />
        <p className="text-center mt-4">
          Our approach to education is holistic. We believe that sport is a form
          of education as much as is solving mathematical equations. We value
          every passion. Find how we align students' passion with academics.
        </p>
        <div className="mt-4 sm:mt-8">
          <CTAInlink
            text="academics"
            linkto="/academics"
            className="bg-gray-800 text-white hover:bg-red-800 hover:text-white"
          />
        </div>
      </HomePageSection>
      <HomePageSection>
        <h2 className="text-center text-4xl md:text-6xl section-head">
          Finding <span className="text-red-700">Passion</span> at Bloom
        </h2>
        <p className="mt-4 text-center">
          One might wonder how Bloom enables students to find their passion
          before letting them pursue them. As we have seen at Bloom, passion is
          contagious. Our finding is that growing up in an environment full of
          passionate teachers and friends who participate and organize various
          events throughout the year enables new students to discover their
          passion too.
        </p>
        <div className="mt-4 sm:mt-8 lg:mt-16 flex flex-col justify-center items-center">
          <Img
            className="w-full"
            fluid={data.FBTeamOnGround.childImageSharp.fluid}
            alt="Students on ground, ready to play football"
            draggable={false}
          />
          <p className="text-sm mt-2 text-center">
            We believe that sport, like many other seemingly vocational
            disciplines, is not just a tool that helps one excel in personal and
            academic life, but is a much border discipline of life itself.
          </p>
        </div>
      </HomePageSection>

      <HomePageSection>
        <h2 className="self-start section-head text-5xl sm:text-6xl lg:text-8xl">
          Enabling Students'{" "}
          <span className="section-head text-5xl sm:text-6xl lg:text-8xl text-red-700">
            {" "}
            Creation{" "}
          </span>{" "}
        </h2>
        <div>
          <p className="mt-16">
            Bloom is nothing but an institution that enables students to
            discover and pursue their passion. It is the students who are the
            real rockstars.
          </p>
          <p className="mt-4 sm:mt-8 lg:mt-16">
            If you want to find out what our students are doing, the following
            links should guide you.
          </p>
          <ul className="mt-8 sm:mt-12 lg:mt-16">
            <li className="mt-4 sm:mt-0">
              <Link
                to="/stories"
                className="text-gray-800 hover:text-red-700  text-3xl sm:text-4xl font-medium md:my-2 inline-block border-b-2 hover:border-red-700 leading-tight"
              >
                Stories of Bloom{" "}
                <span className="text-sm">by Students and Teachers</span>
              </Link>
            </li>
            <li className="mt-4 sm:mt-0">
              <Link
                to="/events"
                className="text-gray-800 hover:text-red-700  text-3xl sm:text-4xl font-medium md:my-2 inline-block border-b-2 hover:border-red-700 leading-tight"
              >
                Student Club Events
              </Link>
            </li>
            <li className="mt-4 sm:mt-0">
              <Link
                to="/calendar"
                className="text-gray-800 hover:text-red-700  text-3xl sm:text-4xl font-medium md:my-2 inline-block border-b-2 hover:border-red-700 leading-tight"
              >
                Calendar of Events
              </Link>
            </li>
          </ul>
        </div>
      </HomePageSection>
      <HomePageSection>
        <h2 className="self-start section-head text-5xl sm:text-6xl lg:text-8xl">
          Education For{" "}
          <span className="section-head text-5xl sm:text-6xl lg:text-8xl text-red-700">
            All
          </span>
        </h2>
        <div>
          <p className="mt-4">
            Bloom is an incredibly diverse family of students. Our students come
            from more than 35 districts from the rugged Himalayas, hills, and
            the arable Terai. This has only been possible because of the
            scholarship we award through the{" "}
            <a
              className="border-b-2 border-red-700 hover:text-red-700"
              href="http://bloomnf.org/"
            >
              Bloom Nepal Foundation.
            </a>
          </p>
          <Img
            className="w-full mt-4 sm:mt-8 lg:mt-16"
            fluid={data.Subekshya.childImageSharp.fluid}
            alt="a team of student standing together"
            draggable={false}
          />
          <p className="mt-4 sm:mt-8 lg:mt-16">
            Become a part of our scholarship by:
          </p>
          <div className="mt-8 sm:mt-12 lg:mt-16">
            <CTAInlink
              className="text-white inline-block bg-red-800 hover:bg-red-700"
              linkto="/scholarship"
              text="Applying for Scholarship"
            />
            <a
              className="mt-4 md:mt-8 inline-block text-white bg-gray-700 hover:bg-red-700 px-4 py-3 md:px-10 md:py-4 uppercase button-outline "
              href="https://bloomnf.org/gift-education"
            >
              Supporting Scholarship Fund
            </a>
          </div>
        </div>

        <h2 className="my-8 section-head text-6xl lg:text-8xl">
          Be{" "}
          <span className="section-head text-6xl lg:text-8xl text-red-700">
            {" "}
            Bold{" "}
          </span>{" "}
        </h2>

        <div>
          <p className="text-center">
            Embrace change. Be a Bloom Nepal Student.
          </p>
        </div>
        <div className="mt-16">
          <CTAInlink
            text="Admission"
            linkto="/admission"
            className="hover:bg-red-700 text-white bg-red-800 hover:text-white"
          />
        </div>
      </HomePageSection>
      <div className="flex flex-col justify-center items-center text-xl sm:text-2xl px-4 md:px-6 lg:px-8">
        <Awards data={data} />
      </div>
    </Layout>
  );
}
